<template>
  <div>
    <b-card class="mb-4">
      <div>Seu acesso vence em {{ tempoAcesso }}</div>
    </b-card>
    <b-card class="mb-4">


            <form ref="anyName" class="filtros-auditoria res-inputs">
               <div class="p-1 flex-fill">
                <input-date
                  id="de"
                  :label="$t('AUDITORIA.DE')"
                  v-model="form.DataVencimentoDe"
                  @onChange="(value) => funcaoteste(value)"
                />
              </div>
              <div class="p-1 flex-fill">
                <input-date
                  id="ate"
                  :label="$t('AUDITORIA.ATE')"
                  v-model="form.DataVencimentoAte"
                  @onChange="(value) => funcaoteste(value)"
                />
              </div>

              <div class="p-1 flex-fill">
                <input-text
                  ref="CpfCnpjCedente"
                  label="CPF ou CNPJ do Cedente"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  v-model="form.CpfCnpjCedente"
                />
              </div>
              <div class="flex-fill empresa-sacado">
                <input-text
                  ref="NomeCedente"
                  label="Nome do Fornecedor"
                  v-model="form.NomeCedente"
                />
              </div>

              <div class="flex-fill empresa-sacado">
                <input-text
                  ref="Titulo"
                  label="Título"
                  v-model="form.Titulo"
                />
              </div>
            </form>

            <hr />

            <div class="buttons-filter">
              <b-button
                class="button-filter"
                @click="
                  () => {
                    limparFiltro();
                  }
                "
                >Resetar</b-button
              >
              <b-button
                class="button-filter"
                variant="primary"
                v-on:click="
                  () => {
                    if (!validarFiltro()) return;

                    mostrarFiltro();

                    listar();
                  }
                "
                >Aplicar</b-button
              >
            </div>
      <div class="header-table-operacoes">
        <b-form-group
          label="Por Pagina"
          label-for="per-page-select"
          label-class="fw-medium"
          class="por-pagina"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            @change="setPorPagina"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          @change="setPage"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </div>
      <b-table @sort-changed="sortingChanged" head-variant="light" hover :items="items" :fields="campos" >
         <template style="width: 50px" v-slot:cell(flagVencido)="item">
          <div  :class="`badge-table ${item.item.situacao == 'Em Atraso' ? 'Vencido' :item.item.situacao == 'Baixado' ? 'Baixado' : 'Aberto'}`">
            {{item.item.situacao}}
          </div>
        </template>
        <template style="width: 50px" v-slot:cell(status)="item">
          <div :class="`badge-table ${item.item.status}`">
            {{$t(`STATUS.${item.item.status.toUpperCase()}`)}}
          </div>
        </template>
        <template style="width: 50px" v-slot:cell(arquivo)="item">
          <div v-if="item.item.flagBaixar">
            <InputCheckbox
              @onChange="() => selectItem(item.index)"
              :value="item.item.active ? true : false"
            />
          </div>
        </template>
      </b-table>
    </b-card>

    <b-row class="operacoes-infos res-inputs">
      <input-text
        :value="valorTotal"
        class="sacado-text"
        :readonly="true"
        :label="$t('SACADO.VALOR_TOTAL')"
      />
      <input-text
        :value="valorTotalAtualizado"
        class="sacado-text"
        :readonly="true"
        :label="$t('SACADO.VALOR_TOTAL_ATUALIZADO')"
      />
      <div @click="obterBoletos()" :class="classBaixarArquivo">
        Baixar Arquivos
      </div>
    </b-row>
  </div>
</template>

<script>
import SacadoService from "@/common/services/sacado/sacado.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "@/common/utils/helpers";
import jwt from "@/common/utils/jwt";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import InputDate from "@/components/inputs/InputDate";
import InputCheckbox from "@/components/inputs/InputCheckbox";
import InputText from "@/components/inputs/InputText";


export default {
  name: "AcessoSacado",
  components: {
    InputText,
    InputCheckbox,
    InputDate,
    
  },
  data() {
    return {
      filtro: false,
      campos: [
        {
          key: "arquivo",
          label: "Arquivo",
          thClass: "item-table",
        },
        {
          key: "fornecedor",
          label: this.$t("SACADO.FORNECEDOR"),
          sortable: true,
          thClass: "fornecedor"
        },
        {
          key: "titulo",
          label: this.$t("SACADO.TITULO"),
          sortable: true,
        },
        {
          key: "dataVencimento",
          label: this.$t("SACADO.DATA_VENCIMENTO"),
          formatter: (value) => {
            if (value) return helpers.formatarDataBr(value);
          },
          sortable: true,
        },
        {
          key: "flagVencido",
          label: this.$t("SACADO.SITUACAO"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("SACADO.STATUS"),
          sortable: true,
        },
        {
          key: "valor",
          label: this.$t("SACADO.VALOR"),
          formatter: (value) => {
            if (value) return helpers.formatarValor(value);
          },
          sortable: true,
        },
        {
          key: "valorAtualizado",
          label: this.$t("SACADO.VALOR_ATUALIZADO"),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatarValor(value);
          },
        },
      ],
      valorTotal: 0,
      valorTotalAtualizado: 0,
      items: [],
      arquivosSelecionados: [],
      form: {},
      totalRows: 1,
      currentPage: 1,
      tempoAcesso: "",
      perPage: 10,
      validField: [],
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t("GERAL.PAGINACAO") },
      ],
    };
  },
  watch:{
    sortBy(){
      console.log("this.sortBy", this.sortBy)
    }
  },
  mounted() {
    this.obterToken();
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: "Acesso Sacado" },
      { titulo: "2º Via do Boleto" },
    ]);
  },
  computed: {
    itens() {
      return this.items;
    },
    classBaixarArquivo() {
      return this.arquivosSelecionados.length <= 0
        ? "btn-operacoes orange btn-desativado"
        : "btn-operacoes orange";
    },
  },
  methods: {
    mostrarFiltro(){
      this.filtro = !this.filtro;
    },
    limparFiltro() {
      this.$refs.anyName.reset();
      this.form = {};
    },
    sortingChanged(context) {
      this.items = [];
      this.form.CampoOrdenacao = helpers.primeiraLetraMaiuscula(context.sortBy)
      this.form.Ascendente = !context.sortDesc;

      this.listar();
    },
    selectItem(index) {
      let items = this.items;

      this.$set(this.items[index], "active", !this.items[index].active);
      items[index].active = this.items[index].active;
      this.arquivosSelecionados = [];

      for (let i = 0; i < items.length; i++) {
        if (items[i].active) {
          this.arquivosSelecionados.push({
            codigoEmpresa: items[i].codigoEmpresa,
            codigoFilial: items[i].codigoFilial,
            sequencialTitulo: items[i].sequencialTitulo,
            titulo: items[i].titulo,
          });
        }
      }

      //
    },
     downloadPDF(pdf, _fileName) {
      let type = this.arquivosSelecionados.length > 1 ? "zip" : "pdf";
      const linkSource = `data:application/${type};base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = _fileName;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    validarFiltro() {
      let arValidation = [];
      arValidation.push(this.$refs.CpfCnpjCedente.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    setPage(e) {
      this.listar(e, undefined);
      this.currentPage = e;
    },
    setPorPagina(e) {
      this.listar(undefined, e);
      this.perPage = e;
    },
    obterToken() {
      this.tempoAcesso = helpers.formatarDataTime(
        new Date(parseInt(jwt.buscarTempo()))
      );
      this.listar();
    },
    obterBoletos() {
      this.$store.dispatch(START_LOADING);

      SacadoService.obterBoletos(this.arquivosSelecionados)
        .then((result) => {
          let itens = result.data.data;

          this.downloadPDF(itens.arquivoBoleto, itens.arquivoNome);
          
          console.log("itens", itens);
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    listar(numeroPagina, porPagina) {
      this.$store.dispatch(START_LOADING);

      SacadoService.listarBoletos({
        ...this.form,
        NumeroDaPagina: (numeroPagina || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          let itens = result.data.data.itens;
          console.log("itens", itens);
          let valorTotal = 0;
          let valorTotalAtualizado = 0;
          for (let i = 0; i < itens.length; i++) {
       
            valorTotal += itens[i].valor;
            valorTotalAtualizado += itens[i].valorAtualizado;
          }

          this.totalRows = result.data.data.paginacao.totalDeElementos;

          this.valorTotal = helpers.formatarValor(valorTotal);
          this.valorTotalAtualizado =
            helpers.formatarValor(valorTotalAtualizado);
          this.items = itens;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
